<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <presupuestocli-add-form
          :idcliente="routeParams.idcliente"
          :idcliente-potencial="routeParams.idcliente_potencial"
          :es-tecnico="!!usuarioIdtecnico"
          :es-comercial="!!usuarioIdcomercial"
          :es-vigilante="!!usuarioIdvigilante"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import PresupuestocliAddForm from './components/PresupuestocliAddForm'
import Data from './PresupuestocliAddData'
import { get } from 'vuex-pathify'

export default {
  components: {
    PresupuestocliAddForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir presupuesto'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    formInvalid () {
      this.$alert.showSnackbarError('Alguno de los pasos del asistente contiene datos incorrectos')
    },
    async submitForm () {
      const inserted = await Data.insert(this, this.formData, this.usuarioIdcomercial)
      this.$appRouter.replace({
        name: 'presupuestos__presupuestocli-view',
        params: {
          idpresupuestocli: inserted.idpresupuestocli,
        },
      })
    },
  },
}
</script>
